exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ajanvaraus-tsx": () => import("./../../../src/pages/ajanvaraus.tsx" /* webpackChunkName: "component---src-pages-ajanvaraus-tsx" */),
  "component---src-pages-ennensaapumista-tsx": () => import("./../../../src/pages/ennensaapumista.tsx" /* webpackChunkName: "component---src-pages-ennensaapumista-tsx" */),
  "component---src-pages-hoidot-tsx": () => import("./../../../src/pages/hoidot.tsx" /* webpackChunkName: "component---src-pages-hoidot-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lahjakortit-tsx": () => import("./../../../src/pages/lahjakortit.tsx" /* webpackChunkName: "component---src-pages-lahjakortit-tsx" */),
  "component---src-pages-louna-tsx": () => import("./../../../src/pages/louna.tsx" /* webpackChunkName: "component---src-pages-louna-tsx" */)
}

